<template>
  <div class="container-fluid">
    <list-desktop
      v-if="!$isMobile()"
      :empty="empty"
      :loading="loading"
      :data="data"
      :queriedData="queriedData"
      :dataPagination="dataPagination"
      v-on:add_or_edit="addOrEdit"
      v-on:search_q="searchBack"
      v-on:details_customers="details"
      v-on:load_customers="loadCustomers"
      v-on:delete_customer="deleteCustomer"
      v-on:approuved="approuved"
      v-on:desapprouved="desapprouved"
      v-on:per_page_selected="nbrPerPageSelected"
    >
    </list-desktop>
    <list-mobile
      v-if="$isMobile()"
      :empty="empty"
      :loading="loading"
      :data="data"
      :queriedData="queriedData"
      :dataPagination="dataPagination"
      v-on:add_or_edit="addOrEdit"
      v-on:search_q="searchBack"
      v-on:details_customers="details"
      v-on:load_customers="loadCustomers"
      v-on:delete_customer="deleteCustomer"
      v-on:approuved="approuved"
      v-on:desapprouved="desapprouved"
      v-on:per_page_selected="nbrPerPageSelected"
    >
    </list-mobile>

    <!-- Store nearly customer and update -->
    <mdb-modal
      centered
      size="lg"
      direction="top"
      position="top"
      :show="modalCustomer.show"
    >
      <form @submit.prevent="modalCustomer.edit ? update() : save()">
        <mdb-modal-header>
          <mdb-modal-title>{{ modalCustomer.title }}</mdb-modal-title
          ><br />
        </mdb-modal-header>
        <mdb-modal-body class="">
          <div
            v-if="!modalCustomer.edit"
            class="import-section d-flex justify-content-end"
          >
            <mdb-btn
              @click="importCustomers()"
              color="info"
              size="sm"
              icon="file-upload"
              class="px-2"
            >
              Importer clients
            </mdb-btn>
          </div>
          <fieldset>
            <legend class="color-normal-reversed">Généralité</legend>
            <mdb-row>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.customer.errors.code_client ? true : false"
                  :invalidFeedback="$store.state.customer.errors.code_client"
                  v-model="form.code_client"
                  @change="codeFormatter"
                  outline
                  label="Code Client"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="8">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.customer.errors.designation ? true : false"
                  :invalidFeedback="$store.state.customer.errors.designation"
                  v-model="form.designation"
                  outline
                  label="Désignation"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  :validation="$store.state.customer.errors.ifu ? true : false"
                  :invalidFeedback="$store.state.customer.errors.ifu"
                  type="number"
                  v-mask="'#############'"
                  min="0"
                  outline
                  label="IFU"
                  v-model="form.ifu"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="RCCM"
                  v-model="form.rccm"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  :validation="$store.state.customer.errors.num_contrat ? true : false"
                  :invalidFeedback="$store.state.customer.errors.num_contrat"
                  outline
                  label="N° Contrat"
                  v-model="form.num_contrat"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-date-picker-2
                  wrapperClass=""
                  v-mask="'####-##-##'"
                  autoHide
                  outline
                  v-model="form.date_debut_activity"
                  label="Début d'activité"
                />
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Responsable"
                  v-model="form.responsable"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  outline
                  label="Commercial"
                  v-model="form.commercial"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset>
            <legend class="color-normal-reversed">Contact</legend>
            <mdb-row>
              <mdb-col sm="12" md="6" lg="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.phone"
                  v-mask="'(###) ## ### ###'"
                  placeholder="(000)00 000 000"
                  outline
                  label="Téléphone"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="6" lg="4">
                <mdb-input
                  :validation="$store.state.customer.errors.email ? true : false"
                  :invalidFeedback="$store.state.customer.errors.email"
                  v-model="form.email"
                  outline
                  label="Adresse mail"
                ></mdb-input>
              </mdb-col>
              <mdb-col sm="12" md="12" lg="4">
                <mdb-input
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.address"
                  outline
                  label="Adresse physique"
                ></mdb-input>
              </mdb-col>
            </mdb-row>
          </fieldset>
          <fieldset>
            <legend class="color-normal-reversed">Option Avancée</legend>
            <mdb-row>
              <mdb-col sm="12" md="6" lg="4" class="mt-2">
                <label for=""> Client exonéré de la TVA?</label>
                <mdb-switch v-model="form.exonere_tva" offLabel="Non" onLabel="Oui" />
              </mdb-col>
              <!-- <mdb-col sm="12" md="6" lg="4" class="mt-2">
                <label for=""> AiB vente en gros?</label>
                <mdb-switch v-model="form.has_aib" offLabel="Non" onLabel="Oui" />
                <mdb-input v-if="form.has_aib"
                  wrapperClass=""
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  v-model="form.rate_aib"
                  outline
                  label="Taux AIB"
                ></mdb-input>
              </mdb-col>  -->
              <mdb-col
                v-if="$store.state.auth.company.setting.regime_fiscal == 'tps'"
                sm="12"
                md="6"
                lg="4"
                class="mt-2"
              >
                <label for="">Client Etatique ?</label>
                <mdb-switch
                  v-model="form.is_gouv_customer"
                  offLabel="Non"
                  onLabel="Oui"
                />
              </mdb-col>
              <mdb-col sm="12" md="6" lg="4" class="mt-2">
                <label for="">Etat du client?</label>
                <mdb-switch
                  v-model="form.is_active"
                  offLabel="Désactivé"
                  onLabel="Activé"
                />
              </mdb-col>
            </mdb-row>
          </fieldset>
        </mdb-modal-body>
        <mdb-modal-footer class="">
          <mdb-btn size="md" color="primary" type="submit" :disabled="modalCustomer.btn">
            <span v-if="!modalCustomer.btn">Sauvegarder</span>
            <span
              v-if="modalCustomer.btn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalCustomer.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn size="md" outline="primary" @click="modalCustomer.show = false"
            >Fermer</mdb-btn
          >
        </mdb-modal-footer>
      </form>
    </mdb-modal>

    <mdb-modal
      :show="modalCustomerDetails.show"
      size="lg"
    >
      <mdb-modal-header>
        <mdb-modal-title class="pl-2">
          <span
            v-if="modalCustomerDetails.content.is_active === 1"
            class="badge rounded-pill bg-success"
            style="height: 10px; width: 10px; display: inline-block"
          ></span>
          <span
            v-else
            class="badge rounded-pill bg-danger"
            style="height: 10px; width: 10px; display: inline-block"
          ></span>
          {{ modalCustomerDetails.title }}</mdb-modal-title
        >
      </mdb-modal-header>
      <mdb-modal-body class="modal-tec card-reversed color-normal-reversed px-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <table class="modal-details">
              <tbody>
                <tr>
                  <th>Code Client</th>
                  <td>{{ modalCustomerDetails.content.code_client }}</td>
                </tr>
                <tr>
                  <th>Désignation</th>
                  <td>
                    <span
                      v-if="modalCustomerDetails.content.is_active === 1"
                      class="badge rounded-pill bg-success"
                      style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    <span
                      v-else
                      class="badge rounded-pill bg-danger"
                      style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    {{ modalCustomerDetails.content.designation }}
                  </td>
                </tr>
                <tr>
                  <th>IFU</th>
                  <td>{{ modalCustomerDetails.content.ifu }}</td>
                </tr>
                <tr>
                  <th>RCCM</th>
                  <td>{{ modalCustomerDetails.content.rccm }}</td>
                </tr>
                <tr>
                  <th>Téléphone</th>
                  <td>{{ modalCustomerDetails.content.phone }}</td>
                </tr>

                <tr>
                  <th>Adresse Email</th>
                  <td>{{ modalCustomerDetails.content.email }}</td>
                </tr>
                <tr>
                  <th>Adresse Physique</th>
                  <td>{{ modalCustomerDetails.content.address }}</td>
                </tr>
                <tr>
                  <th>Responsable</th>
                  <td>{{ modalCustomerDetails.content.responsable }}</td>
                </tr>
                <tr>
                  <th>Commercial</th>
                  <td>{{ modalCustomerDetails.content.commercial }}</td>
                </tr>
                <tr>
                  <th>Début d'activité</th>
                  <td>{{ modalCustomerDetails.content.date_debut_activity }}</td>
                </tr>
                <tr>
                  <th>N° Contrat</th>
                  <td>{{ modalCustomerDetails.content.num_contrat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6">
            <table class="modal-details">
              <tbody>
                <tr>
                  <th>Plafond de crédit</th>
                  <td>{{ modalCustomerDetails.content.credit_plafond | formatNumber }} XOF</td>
                </tr>
                <!--

                <tr>
                  <th>Délai de règlement</th>
                  <td>{{ modalCustomerDetails.content.delai_reglement }}</td>
                </tr>
                  <tr>
                      <th>Type de réduction</th>
                      <td>{{modalCustomerDetails.content.reduction_type}}</td>
                  </tr>
                  <tr>
                      <th>Réduction</th>
                      <td>{{modalCustomerDetails.content.reduction}}</td>
                  </tr>
                -->
                <!-- <tr>
                  <th>Taux AiB</th>
                  <td>
                    {{ modalCustomerDetails.content.rate_aib }}
                  </td>
                </tr> -->
                <tr>
                  <th>Compte</th>
                  <td>
                    {{ modalCustomerDetails.content.account }}
                  </td>
                </tr>
                <tr>
                  <th>Exonéré TVA ?</th>
                  <td>
                    <span v-if="modalCustomerDetails.content.exonere_tva === 1">
                      Oui
                    </span>
                    <span v-else> Non </span>
                  </td>
                </tr>
                <!-- <tr>
                  <th>AiB vente en gros ?</th>
                  <td>
                    <span v-if="modalCustomerDetails.content.has_aib === 1"> Oui </span>
                    <span v-else> Non </span>
                  </td>
                </tr> -->
                <tr>
                  <th>Le client est préfacturé ?</th>
                  <td>
                    <span v-if="modalCustomerDetails.content.prefacture === 1">
                      Oui
                    </span>
                    <span v-else> Non </span>
                  </td>
                </tr>
                <tr v-if="$store.state.auth.company.setting.regime_fiscal === 'tps'">
                  <th>Client étatique?</th>
                  <td>
                    <span v-if="modalCustomerDetails.content.is_gouv_customer === 1">
                      Oui
                    </span>
                    <span v-else> Non </span>
                  </td>
                </tr>
                <tr>
                  <th>Le client est approuvé ?</th>
                  <td>
                    <span v-if="modalCustomerDetails.content.approuved === 1"> Oui </span>
                    <span v-else> Non </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="">
        <mdb-btn
          v-if="modalCustomerDetails.content.approuved === 0"
          size="md"
          color="danger"
          @click="desapprouved(modalCustomerDetails.content.id)"
          :disabled="desapprouvedBtn"
        >
          <span v-if="!desapprouvedBtn">Rejeter</span>
          <span
            v-if="desapprouvedBtn"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="desapprouvedBtn" class="pl-2">Loading...</span>
        </mdb-btn>
        <mdb-btn
          v-if="modalCustomerDetails.content.approuved === 0"
          size="md"
          color="primary"
          @click="approuved(modalCustomerDetails.content)"
          :disabled="approuvedBtn"
        >
          <span v-if="!approuvedBtn">Approuver</span>
          <span
            v-if="approuvedBtn"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="approuvedBtn" class="pl-2">Loading...</span>
        </mdb-btn>
        <mdb-btn size="md" outline="primary" @click="modalCustomerDetails.show = false"
          >Fermer</mdb-btn
        >
      </mdb-modal-footer>
    </mdb-modal>

    <!-- Import customers -->
    <mdb-modal
      size="md"
      direction="top"
      position="top"
      :show="modalImportCustomer.show"
    >
      <form @submit.prevent="saveImportCustomers()">
        <mdb-modal-header>
          <mdb-modal-title>{{ modalImportCustomer.title }}</mdb-modal-title
          ><br />
        </mdb-modal-header>
        <mdb-modal-body class="">
          <p>
            Télécharger un modèle de fichier Excel.
            <a @click="downloadModelClient()" href="#"> Télécharger </a> <br />
            <span class="text-warning">
              Veuillez à ce que le code client soit unique par client enregistré.</span
            >
          </p>
          <el-upload
            class="upload-demo mt-3"
            ref="customerUpload"
            :action="$store.state.url + 'api/imports/customers'"
            :headers="{
              Authorization: 'Bearer ' + $store.state.auth.token,
            }"
            :accept="'.xlsx'"
            :limit="1"
            :auto-upload="false"
            :on-success="importSuccess"
            :on-error="importError"
          >
            <el-button slot="trigger" size="medium" type="primary">Ajouter</el-button>
            <!-- <el-button v-if="$store.state.auth.company.logo" style="margin-left: 10px;" size="small" type="danger" @click="deleteLogo">Supprimer</el-button> -->
            <div class="el-upload__tip colo-reversed" slot="tip">
              N'envoyez que des fichiers Excel de type xlsx.
            </div>
          </el-upload>
        </mdb-modal-body>
        <mdb-modal-footer class="">
          <mdb-btn
            size="md"
            color="primary"
            type="submit"
            :disabled="modalImportCustomer.btn"
          >
            <span v-if="!modalImportCustomer.btn">Importer</span>
            <span
              v-if="modalImportCustomer.btn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="modalImportCustomer.btn" class="pl-2">Loading...</span>
          </mdb-btn>
          <mdb-btn size="md" outline="primary" @click="modalImportCustomer.show = false"
            >Fermer</mdb-btn
          >
        </mdb-modal-footer>
      </form>
    </mdb-modal>
  </div>
</template>

<script>
import ListDesktop from "./modules/index/ListDesktop";
import ListMobile from "./modules/index/ListMobile";
import ReductionOption from "@/utils/Options/ReductionOption";
import download from "../../../services/download-fetch";

import {
  mdbBtn,
  mdbSwitch,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbModal,
  mdbModalTitle,
  mdbModalBody,
  mdbModalHeader,
  mdbModalFooter,
  mdbDatePicker2,
} from "mdbvue";
export default {
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - CLIENTS",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbBtn,
    mdbSwitch,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbModal,
    mdbModalTitle,
    mdbModalBody,
    mdbModalHeader,
    mdbModalFooter,
    mdbDatePicker2,
    ListDesktop,
    ListMobile,
  },

  data() {
    return {
      loading: false,
      dataPagination: {
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [
            { text: "10", value: "10", selected: true },
            { text: "25", value: "25" },
            { text: "50", value: "50" },
            { text: "100", value: "100" },
          ],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: [
          "ifu",
          "rccm",
          "designation",
          "email",
          "phone",
          "address",
          "code_client",
        ],
      },

      empty: false,
      approuvedBtn: false,
      desapprouvedBtn: false,
      data: {
        columns: [
          {
            label: "Code Client",
            field: "code_client",
            sort: true,
          },

          {
            label: "Désignation",
            field: "designation",
            sort: true,
          },

          {
            label: "IFU",
            field: "ifu",
            sort: true,
          },
          {
            label: "RCCM",
            field: "rccm",
            sort: true,
          },
          {
            label: "",
            field: "action",
            sort: true,
          },
        ],
        rows: [],
      },

      form: {
        id: "",
        date_debut_activity: "",
        code_client: "",
        num_contrat: "",
        designation: "",
        ifu: "",
        rccm: "",
        type: "",
        phone: "",
        email: "",
        address: "",
        responsable: "",
        commercial: "",
        reduction_type: "quantité",
        reduction: 0,
        account: 0,
        exonere_tva: false,
        has_aib: false,
        rate_aib: 0,
        prefacture: false,
        is_gouv_customer: false,
        is_active: true,
        approuved: true,
      },

      reductionTypeOption: ReductionOption,
      reductionOptions: ReductionOption,

      modalCustomerDetails: {
        show: false,
        edit: false,
        title: "Détails du client",
        content: {},
      },
      modalCustomer: {
        show: false,
        edit: false,
        title: "Ajouter un client",
        content: {},
        btn: false,
      },
      modalImportCustomer: {
        show: false,
        edit: false,
        title: "Importer des clients",
        content: {},
        btn: false,
      },
    };
  },

  methods: {
    addOrEdit(data = null) {
      if (data instanceof Object) {
        this.modalCustomer.title = "Modifier un client";
        this.modalCustomer.content = data;
        this.modalCustomer.edit = true;
        this.formRest(data);
      } else {
        this.modalCustomer.title = "Ajouter un client";
        this.modalCustomer.content = {};
        this.modalCustomer.edit = false;
        this.cleanForm("customer");
      }

      this.modalCustomer.show = true;
    },

    getReduction(value) {
      this.form.reduction_type = value;
    },

    cleanForm(type) {
      if (type === "customer") {
        this.form.id = "";
        this.form.date_debut_activity = "";
        this.form.code_client = "";
        this.form.num_contrat = "";
        this.form.designation = "";
        this.form.ifu = "";
        this.form.rccm = "";
        this.form.type = "";
        this.form.phone = "";
        this.form.email = "";
        this.form.address = "";
        this.form.responsable = "";
        this.form.commercial = "";
        this.form.delai_reglement = 0;
        this.form.reduction = 0;
        this.form.account = 0;
        this.form.exonere_tva = false;
        this.form.has_aib = false;
        this.form.rate_aib = 0;
        this.form.prefacture = false;
        this.form.is_gouv_customer = false;
        this.form.is_active = true;
      }
    },

    formRest(data) {
      this.form.id = data.id;
      this.form.date_debut_activity = data.date_debut_activity;
      this.form.code_client = data.code_client;
      this.form.num_contrat = data.num_contrat;
      this.form.designation = data.designation;
      this.form.ifu = data.ifu;
      this.form.rccm = data.rccm;
      this.form.type = data.type;
      this.form.phone = data.phone;
      this.form.email = data.email;
      this.form.address = data.address;
      this.form.responsable = data.responsable;
      this.form.commercial = data.commercial;
      this.form.reduction_type = data.reduction_type;
      this.form.reduction = data.reduction;
      this.form.account = data.account;
      this.form.exonere_tva = data.exonere_tva === 1 ? true : false;
      this.form.has_aib = data.has_aib === 1 ? true : false;
      this.form.rate_aib = data.rate_aib;
      this.form.is_gouv_customer = data.is_gouv_customer === 1 ? true : false;
      this.form.prefacture = data.prefacture === 1 ? true : false;
      this.form.is_active = data.is_active === 1 ? true : false;

      this.reductionTypeOption = [];

      this.reductionOptions.forEach((reduction) => {
        this.reductionTypeOption.push({
          text: reduction.text,
          value: reduction.value,
          selected: data.reduction_type === reduction.value ? true : false,
        });
      });
    },

    async downloadModelClient() {
      this.$nprogress.start();
      await download({
        isTh: 0,
        method: "get",
        url: "/imports/customers/model",
        filename: "modeles_clients.xlsx",
      })
        .then(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Téléchargé avec succès",
            type: "success",
          });
        })
        .catch(() => {
          this.$nprogress.done();
          this.$notify({
            message: "Echec durant le téléchargement",
            type: "danger",
          });
        });
    },

    details(row) {
      this.modalCustomerDetails.content = row;

      this.modalCustomerDetails.title = `${row.designation}`;

      this.modalCustomerDetails.show = true;
      //
    },

    importCustomers() {
      this.modalCustomer.show = false;

      this.modalImportCustomer.show = true;
      //
    },

    async saveImportCustomers() {
      if (this.$refs.customerUpload.uploadFiles.length > 0) {
        this.modalImportCustomer.btn = !this.modalImportCustomer.btn;
        this.$refs.customerUpload.submit();
      }
    },

    async importSuccess(response) {
      this.modalImportCustomer.btn = !this.modalImportCustomer.btn;
      this.modalImportCustomer.show = false;
      await this.loadCustomers();
      this.$notify({
        message: response.message,
        type: "success",
      });
    },
    importError(error) {
      this.modalImportCustomer.btn = !this.modalImportCustomer.btn;
      this.$notify({
        message: JSON.parse(error.message).message,
        type: "danger",
      });
    },

    async loadCustomers() {
      this.$nprogress.start();
      this.loading = !this.loading;
      await this.$store
        .dispatch("customer/findAll")
        .then((response) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          if (response.data.customers.length === 0) {
            this.empty = true;
          } else {
            this.empty = false;
          }

          this.data.rows = response.data.customers;
        })
        .catch((error) => {
          this.$nprogress.done();
          this.loading = !this.loading;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async save() {
      this.$store.commit("customer/SET_CLEAN");
      this.modalCustomer.btn = !this.modalCustomer.btn;
      this.form.code_code_client =
        this.$store.state.auth.company.id +
        "_" +
        this.form.code_client.toLowerCase().replace(" ", "_");
      await this.$store
        .dispatch("customer/save", this.form)
        .then((response) => {
          this.modalCustomer.show = false;
          this.modalCustomer.btn = !this.modalCustomer.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("customer");
          this.loadCustomers();
        })
        .catch((error) => {
          this.modalCustomer.btn = !this.modalCustomer.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "customer/SET_CODE",
              error.response.data.errors.code_code_client
                ? error.response.data.errors.code_code_client
                : error.response.data.errors.code_client
            );
            this.$store.commit(
              "customer/SET_CONTRAT",
              error.response.data.errors.num_contrat
            );
            this.$store.commit(
              "customer/SET_EMAIL",
              error.response.data.errors.email
            );
            this.$store.commit(
              "customer/SET_DESI",
              error.response.data.errors.designation
            );
            this.$store.commit("customer/SET_IFU", error.response.data.errors.ifu);
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async approuved(row) {
      this.approuvedBtn = !this.approuvedBtn;
      await this.$store
        .dispatch("customer/update", {
          id: row.id,
          code_client: row.code_client,
          code_code_client: row.code_code_client,
          ifu: row.ifu,
          email: row.email,
          designation: row.designation,
          approuved: true,
          is_active: true,
        })
        .then((response) => {
          this.modalCustomerDetails.show = false;
          this.approuvedBtn = !this.approuvedBtn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadCustomers();
        })
        .catch((error) => {
          this.approuvedBtn = !this.approuvedBtn;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async update() {
      this.$store.commit("customer/SET_CLEAN");
      this.modalCustomer.btn = !this.modalCustomer.btn;
      this.form.code_code_client =
        this.$store.state.auth.company.id +
        "_" +
        this.form.code_client.toLowerCase().replace(" ", "_");
      await this.$store
        .dispatch("customer/update", this.form)
        .then((response) => {
          this.modalCustomer.show = false;
          this.modalCustomer.btn = !this.modalCustomer.btn;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.cleanForm("customer");
          this.loadCustomers();
        })
        .catch((error) => {
          this.modalCustomer.btn = !this.modalCustomer.btn;
          if (error.response.data.errors) {
            this.$store.commit(
              "customer/SET_CODE",
              error.response.data.errors.code_code_client
                ? error.response.data.errors.code_code_client
                : error.response.data.errors.code_client
            );
            this.$store.commit(
              "customer/SET_CONTRAT",
              error.response.data.errors.num_contrat
            );
            this.$store.commit(
              "customer/SET_EMAIL",
              error.response.data.errors.email
            );
            this.$store.commit(
              "customer/SET_DESI",
              error.response.data.errors.designation
            );
            this.$store.commit("customer/SET_IFU", error.response.data.errors.ifu);
          }
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async deleteCustomer(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      await this.$store
        .dispatch("customer/delete", id)
        .then((response) => {
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadCustomers();
        })
        .catch((error) => {
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    async desapprouved(id) {
      let res = await this.$store.dispatch("swal/doYouDelete");
      if (res.isDismissed) {
        return;
      }
      this.desapprouvedBtn = !this.desapprouvedBtn;
      await this.$store
        .dispatch("customer/delete", id)
        .then((response) => {
          this.desapprouvedBtn = !this.desapprouvedBtn;
          this.modalCustomerDetails.show = false;
          this.$notify({
            message: response.data.message,
            type: "success",
          });
          this.loadCustomers();
        })
        .catch((error) => {
          this.desapprouvedBtn = !this.desapprouvedBtn;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },

    codeFormatter() {
      this.form.code_client = this.form.code_client.toUpperCase();
    },

    // Systeme de datatable
    totalCalcul() {
      return (this.dataPagination.pagination.total = this.data.rows.length);
    },

    searchQ() {
      if (!this.dataPagination.searchQuery) {
        // return this.pagedData;
        this.totalCalcul();
        return new Error("Nothing To Search");
      }

      let result = this.data.rows.filter((row) => {
        let isIncluded = false;
        for (let key of this.dataPagination.propsToSearch) {
          let rowValue = "";
          if (row[key] !== null && row[key] !== undefined) {
            rowValue = row[key].toString().toLowerCase();
          }
          if (
            rowValue.includes &&
            rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      this.dataPagination.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },

    nbrPerPageSelected(value) {
      let nbr = value;

      return this.nbrPerPage(nbr);
    },

    nbrPerPage(nbr) {
      return (this.dataPagination.pagination.perPage = nbr);
    },
  },

  created() {
    this.loadCustomers();
  },

  computed: {
    pageData() {
      return this.data.rows.slice(this.from, this.to);
    },

    to() {
      let highBound = this.from + this.dataPagination.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    queriedData() {
      let result = this.searchQ();
      return result instanceof Error ? this.pageData : result;
    },

    from() {
      return (
        this.dataPagination.pagination.perPage *
        (this.dataPagination.pagination.currentPage - 1)
      );
    },

    total() {
      return this.totalCalcul();
    },
  },
};
</script>
